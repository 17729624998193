<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__content container">
        <div class="footer__logo-container">
          <router-link class="footer__logo" to="/">
            <img
              src="@/assets/images/logo-gif.gif"
              width="158"
              height="100"
              alt="Логотип Создатель Энзимов"
            />
          </router-link>
          <div class="footer__socials">
            <h4>Мы в социальных сетях!</h4>
            <div class="footer__socials-list">
              <a
                class="footer__social-link"
                v-for="link in socials"
                :key="link.id"
                :href="link.href"
                target="_blank"
              >
                <v-icon :name="link.icon" width="24" />
              </a>
            </div>
          </div>
        </div>
        <div
          :class="['footer__widget', `footer__widget--${widget.type}`]"
          v-for="widget in widgets"
          :key="widget.id"
        >
          <h3 class="footer__widget-title">{{ widget.title }}</h3>
          <ul class="footer__widget-list">
            <li
              class="footer__widget-item"
              v-for="(line, i) in widget.lines"
              :key="i"
            >
              <router-link
                v-if="line.route"
                class="footer__widget-line"
                :to="{ name: line.route }"
              >
                {{ line.label }}
              </router-link>
              <a
                v-else-if="line.link"
                class="footer__widget-line"
                :href="line.link"
                :target="line.target"
              >
                {{ line.label }}
              </a>
              <span
                v-else
                class="footer__widget-line"
                :class="{ _accent: line.accent }"
              >
                {{ line.label }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <p class="container">
          Международное потребительское сообщество социально-экономических
          программ «Сообщество открытых хозяйств» (МПО СЭП «СОХ») действует на
          основании Закона РФ № 3085-1 от 19.06.1992 "О потребительской
          кооперации (потребительских обществах, их союзах) в Российской
          Федерации"
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import VIcon from "@/components/icon/v-icon";
export default {
  name: "v-footer",
  components: { VIcon },
  data: () => ({
    widgets: [
      {
        id: 1,
        title: "Наши мероприятия",
        type: "catalog",
        lines: [
          // { route: "fermented-products", label: "Ферментированная продукция" },
          // {
          //   route: "fresh-herbal-products",
          //   label: "Свежая растительная продукция",
          // },
          // {
          //   route: "probiotic-enzyme-cosmetics",
          //   label: "Пробиотическая энзимная косметика",
          // },
          // { route: "equipment", label: "Оборудование" },
          // { route: "animal-products", label: "Продукты для животных" },
        ],
      },
      {
        id: 2,
        title: "Информация",
        type: "info",
        lines: [
          { label: "О нашем производстве", route: "about-production" },
          {
            label: "Декларации и сертификаты соответствия",
            route: "declarations-and-certificates",
          },
          { label: "Документы МПО", route: "mpo-documents" },
          {
            label: "Стать участником целевой программы",
            route: "target-program",
          },
        ],
      },
      {
        id: 3,
        title: "Контакты",
        type: "contacts",
        lines: [
          { label: "Телефоны" },
          {
            link: "tel:+79779046901",
            label: "+7 (977) 904 69 01 (ул. Таганская д.25-27) - с 10:00 до 22:00",
          },
          {
            link: "tel:+79779046925",
            label: "+7 (977) 904 69 25 (ОРТК «Фуд Сити») - с 08:00 до 20:00",
          },
          {
            link: "https://t.me/chat_sozdatel_enzimov/53694",
            label: "Написать в отдел доставки",
          },
          {
            link: "https://t.me/chat_sozdatel_enzimov/53696",
            target: "_blank",
            label: "Задать вопрос о продукции",
          },
          {
            link: "https://t.me/IT_sozdatel",
            target: "_blank",
            label: "Написать в техподдержку",
          },
          {
            link: "mailto: sozdatel.369@yandex.ru",
            label: "Email: sozdatel.369@yandex.ru",
          },
        ],
      },
      {
        id: 4,
        title: "Производственные участки для пайщиков",
        type: "production",
        lines: [
          { accent: true, label: "г. Москва, ул. Таганская д.25-27" },
          { link: "tel:+79779046901", label: "+7 (977) 904 69 01" },
          { label: "Как добраться? Ст. м. Марксистская, Таганская" },
          {
            accent: true,
            label:
              "г. Москва, ОРТК «Фуд Сити», п. Сосненское, 22-й км Калужского ш, зд.10, вход 14, линия 23, павильон 023 (Вход 14, налево, первый поворот направо и через 50м Вы на месте)",
          },
          { link: "tel:+79779046925", label: "+7 (977) 904 69 25" },
          {
            label:
              "Как добраться? Ст. м. Теплый Стан, маршрутное такси до ОРТК «Фуд Сити»",
          },
          {
            label: "Прием звонков строго с 10 час до 19 час",
          },
          {
            link: "tel:+79773336369",
            label: "+7 (977) 333 63 69 (Администрация)",
          },
        ],
      },
    ],
    socials: [
      {
        id: 1,
        label: "telegram",
        icon: "icon_telegram",
        href: "https://t.me/enzymetoyou",
      },
      {
        id: 2,
        label: "youtube",
        icon: "icon_youtube",
        href: "https://www.youtube.com/channel/UCGebnmEheJJJnZrUCDYVSXQ",
      },
      {
        id: 3,
        label: "vk",
        icon: "icon_vk",
        href: "https://vk.com/enzymetoyou",
      },
    ],
  }),
};
</script>
