<template>
  <div
    class="app-modal__container"
    :class="{
      'animate__animated animate__fadeInRight animate__faster':
        modal_type === 'ModalRegistration' && is_changed,
      'animate__animated animate__fadeOutLeft animate__faster':
        modal_type !== 'ModalRegistration' && is_changed,
    }"
    v-auto-animate
  >
    <button class="app-modal__close" @click="$emit('close')">
      <v-icon name="icon_close" width="24" />
    </button>
    <h2 class="app-modal__title">Регистрация</h2>
    <form class="form" @submit.prevent="handleSubmit">
      <v-input
        class="form__field form__field--small"
        v-bind="model.last_name"
        :errors="last_name_errors"
        v-model="form.last_name"
      />
      <v-input
        class="form__field form__field--small"
        v-bind="model.first_name"
        :errors="first_name_errors"
        v-model="form.first_name"
      />
      <v-input
        class="form__field form__field--small"
        v-bind="model.middle_name"
        :errors="middle_name_errors"
        v-model="form.middle_name"
      />
      <v-input
        class="form__field form__field--small"
        v-bind="model.email"
        :errors="email_errors"
        v-model="form.email"
      />
      <v-phone-input-v2
        class="form__field form__field--small"
        v-model="form.phone"
        :errors="phone_errors"
        v-bind="model.phone"
      />
      <v-datepicker
        class="form__field form__field--small"
        v-bind="model.birth_date"
        :errors="birth_date_errors"
        v-model="form.birth_date"
      />
      <v-input
        class="form__field form__field--small"
        v-bind="model.address"
        :errors="address_errors"
        v-model="form.address"
      />
      <p class="form__temp1">ВНИМАНИЕ!</p>
      <p class="form__temp">
        ВСТУПАЯ В МПО, В ТЕЧЕНИЕ 30 КАЛЕНДАРНЫХ ДНЕЙ ПОСЛЕ ОПЛАТЫ ВЗНОСОВ,
        НЕОБХОДИМО НАПРАВИТЬ СКАН ИЛИ ФОТО ЗАПОЛНЕННОГО ЗАЯВЛЕНИЯ О ВСТУПЛЕНИИ
        (из раздела «Документы») НА ЭЛЕКТРОННУЮ ПОЧТУ: sozdatel.369@yandex.ru. В
        СЛУЧАЕ НЕ НАПРАВЛЕНИЯ ЗАЯВЛЕНИЯ ВЫ БУДЕТЕ ИСКЛЮЧЕНЫ ИЗ ПОТРЕБИТЕЛЬСКОГО
        ОБЩЕСТВА БЕЗ ВОЗВРАТА ВСТУПИТЕЛЬНОГО ВЗНОСА (СТ.22 ЗАКОНА № 3085-1)!
      </p>
      <v-checkbox
        class="form__field form__field--small"
        v-bind="model.policy"
        :errors="policy_errors"
        v-model="form.policy"
      />
      <div class="app-modal__btn-group">
        <v-button type="submit">Регистрация</v-button>
      </div>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";
import notifications from "@/mixins/notifications";
import VButton from "@/components/ui-kit/v-button";
import VInput from "@/components/ui-kit/v-input";
import VCheckbox from "@/components/ui-kit/v-checkbox";
import VIcon from "@/components/icon/v-icon";
import VDatepicker from "@/components/ui-kit/v-datepicker";
import VPhoneInputV2 from "@/components/ui-kit/v-phone-input-v2.vue";
import { mapGetters } from "vuex";

export default {
  name: "modal-registration",
  components: {
    VPhoneInputV2,
    VDatepicker,
    VIcon,
    VCheckbox,
    VInput,
    VButton,
  },
  mixins: [validationMixin, notifications],

  props: {
    is_changed: {
      type: Boolean,
      default: false,
    },
    modal_type: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    form: {
      last_name: "",
      first_name: "",
      middle_name: "",
      email: "",
      phone: "",
      birth_date: null,
      address: "",
      policy: false,
    },

    model: {
      last_name: {
        label: "Фамилия",
        type: "text",
        placeholder: "Введите фамилию",
        required: true,
        trim: true,
      },
      first_name: {
        label: "Имя",
        type: "text",
        placeholder: "Введите имя",
        required: true,
        trim: true,
      },
      middle_name: {
        label: "Отчество",
        type: "text",
        placeholder: "Введите отчество",
        required: true,
        trim: true,
      },
      email: {
        label: "Email",
        type: "text",
        placeholder: "Введите email",
        required: true,
        trim: true,
      },
      phone: {
        label: "Телефон",
        type: "text",
        placeholder: "+7 (---) --- -- --",
        required: true,
        trim: true,
      },
      birth_date: {
        label: "Дата рождения",
        placeholder: "дд.мм.гггг.",
        required: true,
      },
      address: {
        label: "Адрес доставки",
        type: "text",
        placeholder: "Введите адрес для доставки",
      },
      policy: {
        label:
          "Принимаю условия <a href='#'>Политики обработки персональных данных</a> и даю <a href='#'>Согласие на обработку моих персональных данных</a>",
        size: "small",
      },
    },
  }),

  validations: {
    form: {
      last_name: { required },
      first_name: { required },
      middle_name: { required },
      email: { email, required },
      phone: { required },
      birth_date: { required },
      address: { required },
      policy: {
        mostBeTrue: (value) => value,
      },
    },
  },

  computed: {
    ...mapGetters({
      registration_form: "auth/registration_form",
    }),

    last_name_errors() {
      const errors = [];
      if (!this.$v.form.last_name.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    first_name_errors() {
      const errors = [];
      if (!this.$v.form.first_name.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    middle_name_errors() {
      const errors = [];
      if (!this.$v.form.middle_name.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    email_errors() {
      const errors = [];
      if (!this.$v.form.email.email) errors.push("Email не валидный");
      if (!this.$v.form.email.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    phone_errors() {
      const errors = [];
      if (!this.$v.form.phone.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    birth_date_errors() {
      const errors = [];
      if (!this.$v.form.birth_date.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    address_errors() {
      const errors = [];
      if (!this.$v.form.address.required)
        errors.push("Обязательно для заполнения");
      return this.$v.$error ? errors : [];
    },
    policy_errors() {
      const errors = [];
      if (!this.$v.form.policy.mostBeTrue) errors.push("Обязательное условие");
      return this.$v.$error ? errors : [];
    },
  },

  // mounted() {
  //   this.initComponent();
  // },

  methods: {
    initComponent() {
      if (this.registration_form) {
        Object.keys(this.form).forEach((key) => {
          this.form[key] = this.registration_form[key];
        });
      }
    },

    async handleSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        try {
          const data = JSON.parse(JSON.stringify(this.form));
          data.policy = undefined;
          this.$store.commit("auth/UPDATE_REGISTRATION_FORM", data);
          this.changeModal("ModalAuthChoice");
        } catch (e) {
          this.$emit("error");
          this.renderAsyncErrors(e);
        }
      } else {
        this.$emit("error");
      }
    },

    changeModal(modal) {
      this.$emit("changeModal", modal);
    },
  },
};
</script>
